
export default {
  computed: {
    isNutri() {
      return (
        this.$route.matched
          .map((it) => it.meta)
          .reduce((res, it) => ({ ...res, ...it }), {}).isNutritionist ?? false
      )
    },
  },
}
