<template>
  <c-flex
    flex-dir="column"
    flex="1"
    px="15px"
  >
    <c-flex
      v-if="isClient"
      mt="15px"
    >
      <c-button
        rounded="lg"
        bg="#F2F9F9"
        @click.stop.prevent="showKalenderModal = true"
      >
        <c-image
          :src="require('@/assets/icon-calendar-week.svg')"
          alt="icon calendar"
        />
        <c-text
          ml="5px"
          font-style="normal"
          font-weight="500"
          font-size="14px"
          line-height="150%"
          color="#4F4F4F"
        >
          Week {{ selectedWeek }}
        </c-text>
      </c-button>
    </c-flex>
    <c-flex
      mt="15px"
      overflow-x="auto"
      align-items="center"
      justify-content="space-around"
    >
      <c-button
        v-if="isClient"
        variant="ghost"
        rounded="full"
        h="24px"
        w="24px"
        p="0"
        min-w="0"
        type="button"
        :is-disabled="selectedWeek === 1"
        @click.prevent="selectedWeek -= 1"
      >
        <c-icon
          name="chevron-left"
          size="24px"
          color="primary.400"
        />
      </c-button>
      <badge-week
        v-for="(week, index) in weeksRange"
        :key="week"
        v-chakra
        :selected="week === +($route.query.day || 1)"
        :week="week"
        :dotted="activeDays.includes(week)"
        :ml="index === 0 ? '' : '10px'"
        @click="
          $router.replace({
            query: { ...$route.query, day: week },
          })
        "
      />
      <c-button
        v-if="isClient"
        variant="ghost"
        rounded="full"
        h="24px"
        w="24px"
        p="0"
        min-w="0"
        type="button"
        @click.prevent="selectedWeek += 1"
      >
        <c-icon
          name="chevron-right"
          size="24px"
          color="primary.400"
        />
      </c-button>
    </c-flex>
    <c-flex
      mt="15px"
      flex-dir="column"
    >
      <card-menu
        :menus="_menus"
        @select-menu="selectedRencanaMakan = $event"
      />
    </c-flex>

    <Portal
      v-if="showKalenderModal && selectedDate == null"
      to="main"
    >
      <c-box
        top="0"
        left="0"
        height="100vh"
        width="100vw"
        position="fixed"
        z-index="1001"
      >
        <c-box
          position="absolute"
          top="0"
          left="0"
          height="100vh"
          width="100vw"
          bg="#333c"
          @click.stop.prevent="showKalenderModal = false"
        />
        <c-flex
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="white"
          rounded="md"
          flex-dir="column"
          w="700px"
          h="600px"
        >
          <c-flex p="20px">
            <c-button
              variant="ghost"
              h="24px"
              w="24px"
              min-w="0"
              p="0"
              m="0"
              @click.prevent.stop="showKalenderModal = false"
            >
              <c-icon
                name="close"
                size="14px"
                color="#333"
              />
            </c-button>
            <c-text ml="15px">
              Kalender makan
            </c-text>
          </c-flex>
          <c-flex
            flex-dir="column"
            overflow-y="auto"
            overflow-x="hidden"
            px="20px"
            pb="20px"
          >
            <v-calendar
              is-expanded
              :locale="{ id: 'id', masks: { weekdays: 'WWWW' } }"
              :rows="12"
              :attributes="attrs"
              :min-date="minDates"
              :max-date="maxDates"
              @dayclick="onDayClicked"
            />
          </c-flex>
        </c-flex>
      </c-box>
    </Portal>
    <Portal
      v-if="showKalenderModal && selectedDate != null"
      to="main"
    >
      <c-box
        top="0"
        left="0"
        height="100vh"
        width="100vw"
        position="fixed"
        z-index="1001"
      >
        <c-box
          position="absolute"
          top="0"
          left="0"
          height="100vh"
          width="100vw"
          bg="#333c"
          @click.stop.prevent="selectedDate = null"
        />
        <c-flex
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="white"
          rounded="md"
          flex-dir="column"
          w="700px"
          h="600px"
        >
          <c-flex p="20px">
            <c-button
              variant="ghost"
              h="24px"
              w="24px"
              min-w="0"
              p="0"
              m="0"
              @click.prevent.stop="selectedDate = null"
            >
              <c-icon
                name="chevron-left"
                size="28px"
                color="#333"
              />
            </c-button>
            <c-text ml="15px">
              Kalender makan
            </c-text>
          </c-flex>
          <c-flex
            flex-dir="column"
            overflow-y="auto"
            overflow-x="hidden"
            px="20px"
            pb="20px"
          >
            <card-menu :menus="selectedMenus" />
          </c-flex>
        </c-flex>
      </c-box>
    </Portal>

    <Portal
      v-if="selectedRencanaMakan"
      to="main"
    >
      <c-box
        top="0"
        left="0"
        height="100vh"
        width="100vw"
        position="fixed"
        z-index="1001"
      >
        <c-box
          position="absolute"
          top="0"
          left="0"
          height="100vh"
          width="100vw"
          bg="#333c"
          @click.stop.prevent="selectedRencanaMakan = null"
        />
        <c-flex
          position="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="white"
          rounded="md"
          flex-dir="column"
          min-w="700px"
          max-h="600px"
        >
          <c-flex p="20px">
            <c-button
              variant="ghost"
              h="24px"
              w="24px"
              min-w="0"
              p="0"
              m="0"
              @click.prevent.stop="selectedRencanaMakan = null"
            >
              <c-icon
                name="close"
                size="14px"
                color="#333"
              />
            </c-button>
            <c-text ml="15px">
              Detail menu
            </c-text>  
          </c-flex>
          <c-flex
            overflow-y="auto"
            overflow-x="hidden"
            px="35px"
            py="10px"
            grid-gap="35px"
          >
            <template v-for="meal in selectedRencanaMakan.meals">
              <c-box
                :key="meal.id"
                w="72px"
                h="auto"
                flex-dir="column"
                align-items="center"
                justify-content="flex-start"
                font-weight="initial"
              >
                <c-image
                  :src="meal.mealIngredientNameGlossary.photoUrl"
                  :alt="meal.mealIngredientNameGlossary.ingredientName"
                  h="72px"
                  w="72px"
                  object-fit="cover"
                  box-shadow="0px 0px 8px 5px rgba(0, 0, 0, 0.12)"
                  border-radius="8px"
                />
                <c-text
                  v-chakra="{
                    'word-wrap': 'anywhere',
                  }"
                  m="0"
                  mt="10px"
                  font-size="12px"
                  text-align="center"
                  color="#333333"
                  max-w="100%"
                  white-space="break-spaces"
                  flex="1"
                >
                  {{ meal.mealIngredientNameGlossary.ingredientName }}
                </c-text>
                <c-text
                  p="0"
                  mt="2px"
                  font-size="12px"
                  text-align="center"
                  color="#888888"
                >
                  {{ meal.dose }} {{ meal.doseUnit }}
                </c-text>
              </c-box>
            </template>
          </c-flex>
          <c-button
            type="button"
            variant="outline"
            variant-color="primary"
            m="20px"
            rounded="full"
            as="router-link"
            :to="{ name: 'glossaries' }"
          >
            Lihat glossary foto makan
          </c-button>
        </c-flex>
      </c-box>
    </Portal>
  </c-flex>
</template>

<script>
import { Portal } from 'portal-vue'
import isNutriMixin from '@/utils/isNutri-mixins'
import BadgeWeek from './badge-week.vue'
import CardMenu from './card-menu.vue'
import getMenuCategory from '@/utils/get-menu-category'

import dayjs from 'dayjs'
import parseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import { mapState } from 'vuex'
import { sortMealCategory } from '@/utils/sort-meal-category'

let day = dayjs.extend(parseFormat).extend(isToday)

export default {
  name: 'MenuRecommendationNonEmpty',
  components: { Portal, CardMenu, BadgeWeek },
  mixins: [isNutriMixin],
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    program: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      programId_: this.programId,
      activeDays: [],
      showKalenderModal: false,
      days: [dayjs()],
      selectedDate: null,
      selectedMenus: [],
      selectedRencanaMakan: null,
    }
  },
  computed: {
    ...mapState({
      mealPlan: (s) => [...s.mealPlan.rencanaMakan, ...s.clients.rencanaMakan],
    }),
    selectedWeek: {
      get() {
        return +(this.$route.query.week ?? 1)
      },
      set(v) {
        if (v <= 0) return
        this.$router.push({
          query: { ...this.$route.query, week: v },
        })
      },
    },
    weeksRange() {
      let length = this.isClient ? 7 : 10
      return Array.from(
        { length },
        (_, i) => i + 1 + (this.selectedWeek - 1) * 7,
      )
    },
    minDates() {
      let startAt = this.program.startAt
      if (startAt == null) {
        let end = dayjs(this.program.endAt)
        startAt = end.subtract(this.program.duration, 'day')
      } else {
        startAt = dayjs(this.program.startAt)
      }
      return startAt.toDate()
    },
    maxDates() {
      return dayjs(this.program.endAt).toDate()
    },
    attrs() {
      return this.days.map((d, index) => ({
        highlight: d.isToday(),
        dates: d.toDate(),
        key: d.isToday() ? 'today' : index,
      }))
    },
    day() {
      return +(this.$route.query.day ?? 1)
    },
    listingMealsPlanData() {
      return [this.clientId, this.programId_, this.day]
    },
    _menus() {
      return [...this.mealPlan]
        .sort((a, b) => sortMealCategory(a.category, b.category))
        .map((menu) => {
          let timeStart = day(menu.timeStart, 'HH:mm:ss').format('HH A')
          let timeEnd = day(menu.timeEnd, 'HH:mm:ss').format('HH A')

          return {
            ...menu,
            title: getMenuCategory(menu.category),
            time: `${timeStart} - ${timeEnd}`,
            meals: this.menus
              .filter((it) => it.mealPlansId === menu.id)
              .flatMap((it) => it.meals)
              .filter((it) => it != null)
              .sort((a, b) => a.sequence - b.sequence)
              .map((it) => ({
                ...it,
                text: `${it.dose} ${it.doseUnit} ${it.foodIngredient}`,
              })),
          }
        })
    },
  },
  watch: {
    listingMealsPlanData: {
      immediate: true,
      handler([clientId, programId]) {
        if (this.isRoleNutritionist) {
          this.$store.dispatch('mealPlan/list', { clientId, programId })
        } else {
          this.$store.dispatch('clients/getRencanaMakan', programId)
        }
      },
    },
    programId: {
      immediate: true,
      handler(programId) {
        this.programId_ = programId
      },
    },
  },
  async mounted() {
    window.dayjs = day

    if (this.programId_ != null) {
      this.$store
        .dispatch('mealPlan/getActiveDays', { programId: this.programId_ })
        .then((it) => it.map((it) => it.day))
        .th
    } else if (this.isRoleNutritionist && this.clientId != null) {
      await this.axios
        .get(`/v1/nutritionist/clients/${this.clientId}/latest-program`)
        .then((r) => r.data.data.id)
        .then((id) => (this.programId_ = id))
    } else {
      let programs = await this.$store.dispatch(
        'profileProgram/listHistoryPrograms',
      )
      this.programId_ = programs.find((it) => it.isLatest)?.id
      if (this.programId_ != null) {
        this.$store
          .dispatch('mealPlan/getActiveDays', { programId: this.programId_ })
          .then((it) => it.map((it) => it.day))
          .then((r) => (this.activeDays = r))
      }
    }
  },
  methods: {
    async onDayClicked($event) {
      if ($event.isDisabled !== false) return
      if (this.programId_ == null) return console.error('programId is null')

      this.selectedDate = $event.date
      let date = day($event.date).format('YYYY-MM-DD')

      await this.axios
        .get(`/v1/clients/meals-recommendation/${this.programId_}/${date}`)
        .then((r) => r.data.data)
        .then((r) => {

          return r.map((it) => ({
            ...it,
            title: getMenuCategory(it.category),
            time: `${day(it.mealPlanData.timeStart, 'hh:mm:ss').format(
              'hh:mm',
            )} - ${day(it.mealPlanData.timeEnd, 'hh:mm:ss').format('hh:mm')}`,
            meals: it.meals.map((m) => ({
              ...m,
              text: `${m.dose} ${m.doseUnit} ${m.foodIngredient}`,
            })),
          }))
        })
        .then((r) => {
          this.selectedMenus = r
        })
    },
  },
}
</script>
