var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "overflow": "auto",
      "pb": "30px"
    }
  }, [_c('c-flex', {
    attrs: {
      "ml": "20px",
      "mt": "20px",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "14px",
      "font-weight": "600"
    }
  }, [_vm._v(" Rekomendasi Menu ")]), !_vm.isNutri ? _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "rounded": "lg",
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          intro: 1
        })
      }
    }
  }, [_c('c-text', [_vm._v("Info")]), _c('c-image', {
    attrs: {
      "ml": "5px",
      "src": require('@/assets/ic-help-filled.svg'),
      "alt": "icon help"
    }
  })], 1) : _vm._e(), _vm.isNutri ? _c('c-button', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "50px",
      "as": "router-link",
      "to": {
        name: 'nutri.rekomendasi-menu.edit'
      }
    }
  }, [_c('c-image', {
    attrs: {
      "mr": "10px",
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "icon"
    }
  }), _vm._v(" Edit ")], 1) : _vm._e()], 1), _vm.isEmpty ? _c('recommended-menu-empty') : _vm._e(), !_vm.isEmpty ? _c('recommended-menu-non-empty', {
    attrs: {
      "menus": _vm.selectedMenu,
      "program": _vm.program
    }
  }) : _vm._e(), _vm.isMealPlanEmpty ? _c('Portal', {
    attrs: {
      "to": "main"
    }
  }, [_c('c-alert-dialog', {
    attrs: {
      "is-open": ""
    }
  }, [_c('c-alert-dialog-overlay'), _c('c-alert-dialog-content', {
    attrs: {
      "pos": "fixed",
      "top": "50%",
      "left": "50%",
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('c-alert-dialog-body', {
    attrs: {
      "py": "20px"
    }
  }, [_vm._v(" Anda belum mengisi rencana makan. ")]), _c('c-alert-dialog-footer', [_c('c-button', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": {
        name: 'nutri.meal-plan',
        params: _vm.$route.params
      }
    }
  }, [_vm._v(" Isi rencana makan ")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }