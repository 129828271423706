<template>
  <c-flex
    flex="1"
    flex-dir="column"
    overflow="auto"
    pb="30px"
  >
    <c-flex
      ml="20px"
      mt="20px"
      align-items="center"
    >
      <c-text
        flex="1"
        font-size="14px"
        font-weight="600"
      >
        Rekomendasi Menu
      </c-text>
      <c-button
        v-if="!isNutri"
        variant="outline"
        variant-color="primary"
        rounded="lg"
        as="router-link"
        :to="{ query: { ...$route.query, intro: 1 } }"
      >
        <c-text>Info</c-text>
        <c-image
          ml="5px"
          :src="require('@/assets/ic-help-filled.svg')"
          alt="icon help"
        />
      </c-button>
      <c-button
        v-if="isNutri"
        variant-color="primary"
        border-radius="50px"
        as="router-link"
        :to="{ name: 'nutri.rekomendasi-menu.edit' }"
      >
        <c-image
          mr="10px"
          :src="require('@/assets/icon-pencil.svg')"
          alt="icon"
        />
        Edit
      </c-button>
    </c-flex>
    <recommended-menu-empty v-if="isEmpty" />
    <recommended-menu-non-empty
      v-if="!isEmpty"
      :menus="selectedMenu"
      :program="program"
    />

    <Portal
      v-if="isMealPlanEmpty"
      to="main"
    >
      <c-alert-dialog is-open>
        <c-alert-dialog-overlay />
        <c-alert-dialog-content
          pos="fixed"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <c-alert-dialog-body py="20px">
            Anda belum mengisi rencana makan.
          </c-alert-dialog-body>
          <c-alert-dialog-footer>
            <c-button
              color="primary.400"
              as="router-link"
              :to="{name:'nutri.meal-plan', params: $route.params}"
            >
              Isi rencana makan
            </c-button>
          </c-alert-dialog-footer>
        </c-alert-dialog-content>
      </c-alert-dialog>
    </Portal>
  </c-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RecommendedMenuEmpty from './empty.vue'
import RecommendedMenuNonEmpty from './non-empty.vue'
import isNutriMixin from '@/utils/isNutri-mixins'
import clientIdMixin from '@/utils/clientId-mixins'

export default {
  name: 'RecommendedMenuPage',
  components: {
    RecommendedMenuEmpty,
    RecommendedMenuNonEmpty,
  },
  mixins: [clientIdMixin, isNutriMixin],
  data() {
    return {
      isLoading: true,
      program: null,
      programId_: null,
    }
  },
  computed: {
    ...mapState({
      menus: (s) => [
        ...s.mealPlan.rekomendasiMenu,
        ...s.clients.rekomendasiMenu,
      ],
      mealPlan: s => s.mealPlan.rencanaMakan,
    }),
    day() {
      return +(this.$route.query.day ?? 1)
    },
    selectedMenu() {
      return this.menus?.filter((it) => it.day === this.day) ?? []
    },
    isEmpty() {
      // return this.menus.length <= 0;
      return false
    },
    isMealPlanEmpty() {
      if (this.isClient) return false
      return !this.isLoading && this.mealPlan.length <= 0
    },
    listingData() {
      return [this.clientId, this.day, this.programId_]
    },
  },
  watch: {
    listingData: {
      immediate: true,
      handler([clientId, day, programId]) {
        if (this.isNutri && clientId != null && programId != null) {
          this.listRekomendasiMenu({ clientId, programId, day })
        }
        if (this.isClient) {
          this.getRekomendasiMenu({ programId, day })
        }
      },
    },
  },
  async mounted() {
    this.isLoading = true
    try {
      if (this.clientId != null && this.isRoleNutritionist) {
        await this.axios
          .get(`/v1/nutritionist/clients/${this.clientId}/latest-program`)
          .then((r) => r.data.data)
          .then((data) => {
            this.program = data
            return data.id
          })
          .then((programId) => {
            this.programId_ = programId
            this.getMealPlanForNutri({ clientId: this.clientId, programId })
          })
      } else if (this.programId != null) {
        this.getRekomendasiMenu({ programId: this.programId, day: this.day })
      } else {
        let programs = await this.$store.dispatch(
          'profileProgram/listHistoryPrograms',
        )
        let program = programs.find((it) => it.isLatest)
        let programId = program.id
        this.program = program
        this.programId_ = programId
        this.getRekomendasiMenu({ programId: programId, day: this.day })
      }
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMealPlanForNutri: 'mealPlan/list',
      listRekomendasiMenu: 'mealPlan/listRekomendasiMenu',
      getRekomendasiMenu: 'clients/getRekomendasiMenu',
    }),
  },
}
</script>
